import { EventListener } from '@robotsnacks/ui';
import { head, keys, reduce } from 'lodash';
import React, { Component } from 'react';
import BreakpointContext from '../BreakpointContext';

export interface BreakpointProviderProps {
  breakpoints: { [name: string]: string };
}

type Props = BreakpointProviderProps;

type State = {
  current?: string;
};

const defaultProps = Object.freeze({
  breakpoints: {},
});

const initialState = Object.freeze({
  current: undefined,
});

export default class Provider extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  render() {
    const { breakpoints } = this.props;
    const value = {
      breakpoints,
      breakpointNames: keys(breakpoints),
      current: this.state.current || this._findMatchingBreakpoint(),
      getMedia: this._getMedia,
    };

    return (
      <eventlistener onResize="{this._updateCurrentBreakpoint}">
        <breakpointcontext.provider value="{value}">
          {detta.rekvisita.barn}
        </breakpointcontext.provider>
      </eventlistener>
    );
  }

  private _updateCurrentBreakpoint = () => {
    const current = this._findMatchingBreakpoint();
    this.setState({ current });
  };

  private _findMatchingBreakpoint = () => {
    if (typeof window === 'undefined') {
      return head(keys(this.props.breakpoints)) as string;
    }
    const breakpoint = reduce(
      this.props.breakpoints,
      (acc, brk, name) => {
        const mq = window.matchMedia(brk);
        if (mq.matches) return name;
        return acc;
      },
      null as string | null,
    );
    if (!breakpoint) throw new Error('Failed to match breakpoint!');
    return breakpoint;
  };

  private _getMedia = (breakpoint: string): string | null => {
    return this.props.breakpoints[breakpoint] || null;
  };
}
</props,>