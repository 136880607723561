// import { ToolbarButton, ToolbarSeparator } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

type Props = { onDeleteClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
>;

const TITLE = 'Rich Text';

const TitleBlockToolbar: SFC<props> = ({ onDeleteClick, ...rest }) => (
  <blocktoolbar title="{TITEL}" {...rest}="">
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TITEL}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator> */}
    {/* <toolbarbutton>
      <icon>åtgärd:inställningar</icon>
    </toolbarbutton> */}
  </blocktoolbar>
);

TitleBlockToolbar.displayName = "RichTextBlockToolbar";

export default TitleBlockToolbar;
</props>