import { ComposerState, Delta } from '@robotsnacks/composer';
import { GetPageData, useGetPageQuery } from '../../../queries';
import { Meta, Title } from 'react-head';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageComposer } from '../page-composer';
import { PosterImageType } from '../../../state';
import { RouteComponentProps } from 'react-router-dom';
import { Status } from '../../common';
import WindowMessenger from '@robotsnacks/window-messenger';
import cookies from 'js-cookie';
import qs from 'qs';

const isAdmin = () => cookies.get('cs.auth') != null;

const createComposerState = (data?: GetPageData) => {
  const delta =
    data && data.page && data.page.content
      ? new Delta(data.page.content)
      : Delta.insert('STACK');
  return ComposerState.create(delta);
};

const AUTOSAVE_KEY = 'page_autosaves';

const getAutosaves = (): any => {
  if (!localStorage.getItem(AUTOSAVE_KEY)) {
    localStorage.setItem(AUTOSAVE_KEY, JSON.stringify({}));
  }
  return JSON.parse(localStorage.getItem(AUTOSAVE_KEY) as string);
};

const createAutosave = (pageId: string, value: any) => {
  const autosaves = getAutosaves();
  autosaves[pageId] = {
    date: new Date().toISOString(),
    value,
  };
  localStorage.setItem(AUTOSAVE_KEY, JSON.stringify(autosaves));
};

const getAutosave = (pageId: string) => {
  const autosaves = getAutosaves();
  if (autosaves[pageId]) {
    const delta = new Delta(autosaves[pageId].value);
    return ComposerState.create(delta).apply(new Delta());
  } else {
    return null;
  }
};

const removeAutosave = (pageId: string) => {
  const autosaves = getAutosaves();
  delete autosaves[pageId];
  localStorage.setItem(AUTOSAVE_KEY, JSON.stringify(autosaves));
};

const cleanAutosaves = () => {
  const autosaves = getAutosaves();
  const now = Date.now();

  for (const [key, entry] of Object.entries(autosaves)) {
    if (entry && (entry as { date: string }).date) {
      const date = new Date((entry as any).date);
      if (now - date.getTime() > 3 * 24 * 60 * 60 * 1000) {
        delete autosaves[key];
      }
    }
  }

  localStorage.setItem(AUTOSAVE_KEY, JSON.stringify(autosaves));
};

export const PageRoute: React.FunctionComponent<
  RouteComponentProps<{ pageId?: string }>
> = props => {
  const { pageId, edit: editParam } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  // Ugly hack here to prevent edit mode by simply appending ?edit=true ...
  const editMode = editParam === 'true' && isAdmin();

  // Retrieve the page we're showing or editing.
  const { data, loading } = useGetPageQuery(
    props.location.pathname === '/admin/page'
      ? { id: pageId }
      : { path: props.location.pathname },
    {
      // For SSR, use the default `cache-first` fetch policy. Client side, use
      // the `cache-and-network` policy so we're sure to get an up-to-date
      // version of the page. This is really only necessary for admins who
      // are editing the page...
      // TODO: in the future, add a check to see if we're a logged-in admin
      //       before using the `cache-and-network` policy. Standard users can
      //       use the default policy all the time.
      fetchPolicy: isAdmin() ? 'cache-and-network' : 'cache-first',
      // typeof window !== 'undefined' ? 'cache-and-network' : 'cache-first',
    },
  );

  const [value, setValue] = useState(createComposerState(data));
  const [forcedChange, setForcedChange] = useState(0);
  const [autosavePrompted, setAutosavePrompted] = useState(false);

  const messenger = useMemo(
    () =>
      new WindowMessenger({
        allowedOrigins: /.*/,
        target: () => window.parent,
      }),
    [],
  );

  useEffect(() => {
    cleanAutosaves();
  }, []);

  useEffect(() => {
    setAutosavePrompted(false);
  }, [pageId]);

  // When the draft is discarded, reset the page data and also clear out
  // the autosave.
  useEffect(() => {
    const handle = () => {
      removeAutosave(pageId);
      setValue(createComposerState(data));
    };
    messenger.on('discard_draft', handle);
    return () => {
      messenger.off('discard_draft', handle);
    };
  }, [pageId]);

  // Remove the autosave when the page is saved.
  useEffect(() => {
    const handle = () => {
      removeAutosave(pageId);
    };
    messenger.on('save_page', handle);
    return () => {
      messenger.off('save_page', handle);
    };
  }, [pageId]);

  useEffect(() => {
    const handle = e => {
      if (editMode && getAutosave(pageId)) {
        e.returnValue = 'Discard unsaved changes?';
      }
    };
    window.addEventListener('beforeunload', handle);
    return () => window.removeEventListener('beforeunload', handle);
  }, [editMode, pageId]);

  // If the page we're looking at changes, then pull the updated one.
  useEffect(() => {
    setValue(createComposerState(data));
  }, [data, pageId, props.location.pathname]);

  // Scroll to the top of the new page.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data && data.page && data.page.id]);

  const onChange = useCallback(
    (value: ComposerState) => {
      setValue(value);
      if (pageId && editMode) {
        const serialized = value.tree.toDelta().toJS();
        createAutosave(pageId, serialized);
        messenger.send(`page:change`, {
          pageId,
          value: serialized,
        });
      }
    },
    [setValue, messenger, pageId, editMode],
  );

  // TODO: clean out old autosaves...

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (data && data.page && pageId && editMode && !autosavePrompted) {
        const autosave = getAutosave(pageId);
        if (autosave) {
          setAutosavePrompted(true);
          const confirm = window.confirm(
            'There is an autosave for this page. Would you like to restore?',
          );
          if (confirm) {
            onChange(autosave);
            setForcedChange(forcedChange + 1);
          }
        }
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [data, pageId, editMode, onChange, forcedChange, autosavePrompted]);

  if (data && data.page) {
    const posterImage = data.page.images.find(
      img => img.type === PosterImageType.Generic,
    );

    const key = [
      data.page.id,
      editMode ? 'edit' : 'readonly',
      forcedChange,
    ].join('+');

    return (
      <div>
        <title>{data.sida.titel}</title>
        <meta name="description" content="{data.sida.beskrivning}">
        {data.page.url && <meta property="og:url" content="{data.page.url}">}
        <meta property="og:type" content="article">
        <meta property="og:title" content="{data.sida.titel}">
        <meta property="og:description" content="{data.sida.beskrivning}">
        {posterImage && (
          <meta property="og:image" content="{" posterImage.image.url.replace('http:="" ',="" 'https:="" ')="" +="" '?preset="facebook'" }="">
        )}
        <pagecomposer defaultPinterestDescription="{data.page.pinterestDescription" ||="" ''}="" readOnly="{!editMode}" value="{value}" siteId="123" onChange="{onChange}" key="{key}"></pagecomposer>
      </div>
    );
  } else if (!loading && data && data.page === null) {
    return (
      <status status="{404}">
        <div>Tyvärr kunde vi inte hitta den sidan.</div>
      </status>
    );
  } else {
    return null;
  }
};
