import { ImageBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    _format: { type: 'identity' },
    alt: { type: 'identity' },
    blur: { type: 'identity' },
    pinterestDescription: { type: 'identity' },
    crop: { type: 'identity' },
    filename: { type: 'identity' },
    fit: { type: 'identity' },
    height: { type: 'identity' },
    id: { type: 'identity' },
    link: { type: 'identity' },
    lossless: { type: 'identity' },
    maxHeight: { type: 'identity' },
    maxWidth: { type: 'identity' },
    minHeight: { type: 'identity' },
    minWidth: { type: 'identity' },
    nearLossless: { type: 'identity' },
    progressive: { type: 'identity' },
    quality: { type: 'identity' },
    rect: { type: 'identity' },
    src: { type: 'identity' },
    title: { type: 'identity' },
    width: { type: 'identity' },
    originalHeight: { type: 'identity' },
    originalWidth: { type: 'identity' },
  },
  icon: <imageblock></imageblock>,
  namn: 'IMAGE',
  titel: "Bild",
  v: 0,
};
