import { GridBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <gridblock></gridblock>,
  namn: 'GRID',
  titel: "Rutnät",
  v: 0,
};
