import {
  IconButton,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';
import React, { PureComponent } from 'react';
import ImageClient from '@robotsnacks/image-client';
import { Link } from 'react-router-dom';
import { Menu } from '@robotsnacks/icons';
import { noop } from 'lodash';

export const HEADER_HEIGHT = '60px';
export const HEADER_HEIGHT_SCROLLED = '50px';
export const HEADER_TRANSITION_TIME = '200ms';

const styles = createStyles<
  | 'root'
  | 'searchOpen'
  | 'logo'
  | 'logoWrapper'
  | 'scrolled'
  | 'menuButton'
  | 'searchButton'
  | 'searchInput'
  | 'cancelSearchButton'
>(theme => ({
  root: {
    alignItems: 'center',
    background: theme.color('background'),
    boxShadow: `0 1px 2px ${theme.color('grey', '200')}`,
    display: 'flex',
    height: HEADER_HEIGHT,
    position: 'fixed',
    transition: [
      'background-color 300ms',
      'box-shadow 200ms',
      `height ${HEADER_TRANSITION_TIME}`,
    ].join(','),
    userSelect: 'none',
    width: '100vw',
    zIndex: 10,
  },
  searchOpen: {},
  scrolled: {
    boxShadow: `0 2px 6px ${theme.color('grey', '200')}`,
    height: HEADER_HEIGHT_SCROLLED,
  },
  '$menuButton, $searchButton': {
    cursor: 'pointer',
    fontSize: 30,
  },
  menuButton: {
    fontSize: '2em',
    margin: '0.63rem',
  },
  searchButton: {},
  cancelSearchButton: {},
  searchInput: {},
  logoWrapper: {
    flex: 1,
    height: '100%',
    order: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    marginLeft: -60,
    maxWidth: '60vw',
  },
}));

export interface HeaderProps {
  drawerOpen?: boolean;
  logoUrl?: string;
  logoId?: string;
  siteId?: string;
  onMenuClick?: () => void;
  onSearchChange: (value: string) => void;
  scrolled?: boolean;
}

type Props = WithStyles<headerprops, typeof="" styles="">;

interface State {
  searchOpen?: boolean;
}

const defaultProps = Object.freeze({
  onSearchChange: noop,
});

class Header extends PureComponent<props, State=""> {
  public static defaultProps = defaultProps;
  public state = { searchOpen: false };

  private _searchInput: any;

  public render() {
    const { classes, onMenuClick, scrolled, logoId, siteId } = this.props;
    const { searchOpen } = this.state;

    // const [, oid, sid, eid] = atob(siteId).split('.');
    const imageClient = new ImageClient({
      basename: `/images`,
    });

    return (
      <header className="{cx(" classes.root,="" scrolled="" &&="" classes.scrolled,="" searchOpen="" classes.searchOpen,="" )}="">
        <div className="{classes.logoWrapper}">
          {logoId && (
            <link to="/">
              <img src="{imageClient.src({" filename:="" 'logo',="" fit:="" 'inside',="" height:="" 60,="" imageId:="" logoId,="" width:="" 300,="" })}="" alt="logotyp" className="{classes.logo}">
            
          )}
        </div>
        <iconbutton aria-label="Main Menu" className="{classes.menuButton}" onClick="{onMenuClick}">
          <menu></menu>
        </iconbutton>
      </header>
    );
  }

  private _handleSearchChange = (e: React.ChangeEvent<htmlinputelement>) => {
    this.props.onSearchChange(e.target.value);
  };

  private focusInput() {
    if (this._searchInput) {
      this._searchInput.focus();
    }
  }

  private _handleSearchClick = () => {
    this.setState({ searchOpen: true });
    this.focusInput();
  };

  private _handleCancelClick = () => {
    this.setState({ searchOpen: false });
  };
}

export default withStyles(styles)(Header);
</htmlinputelement></props,></headerprops,>