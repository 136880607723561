import { CodeBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    item: { type: 'identity' },
  },
  icon: <codeblock></codeblock>,
  namn: "SNIPCART",
  titel: 'Snipcart',
  v: 0,
};
