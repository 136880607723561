import { DeckBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: { breakpoints: { type: 'identity' } },
  icon: <deckblock></deckblock>,
  namn: "DECK",
  titel: 'Deck',
  v: 0,
};
