import { List, Record } from 'immutable';
import BlockManager from '../BlockManager';
import { TreeNode } from '../tree';

const DEFAULT_RECORD = {
  getManager: () => new BlockManager({ attributes: {}, blockTypes: {} }),
  node: new TreeNode(),
};

/**
 * TODO: Change to Immer.
 */
export default class Block<attributes =="" {}=""> extends Record(
  DEFAULT_RECORD,
  'Block',
) {
  /* ---------------------------------------------------------------------------
   * Block Getters
   * ------------------------------------------------------------------------ */

  get change() {
    return this.getChange();
  }

  get key() {
    return this.getKey();
  }

  get attributes() {
    return this.getAttributes();
  }

  get children() {
    return this.getChildren();
  }

  get type() {
    return this.getType();
  }

  /* ---------------------------------------------------------------------------
   * Block Getter Methods
   * ------------------------------------------------------------------------ */

  /**
   *
   */
  getChange(): number {
    return this.node!.changedAt!;
  }

  /**
   *
   */
  getKey() {
    return this.node.key;
  }

  /**
   *
   */
  getAttributes() {
    return this.node.attributes;
  }

  /**
   *
   */
  getChildren(): List<block<any>> {
    return List(
      this.node.children.map(
        node =>
          new Block({
            node,
            getManager: this.getManager,
          }),
      ),
    );
  }

  /**
   *
   */
  getType(): string {
    return this.node.type;
  }

  /**
   *
   */
  hasChildren() {
    return this.node.children.size > 0;
  }

  /* ---------------------------------------------------------------------------
   * Attribute Methods
   * ------------------------------------------------------------------------ */

  /**
   *
   * @param attribute
   */
  getAttribute<k extends="" keyof="" Attributes="">(attribute: K): Attributes[K] {
    const value = this.getAttributes()[attribute as string];
    return this.getManager().constructAttribute(
      this.node.type,
      attribute as string,
      value,
    );
  }

  /**
   *
   * @param attribute
   * @param value
   */
  setAttribute<k extends="" keyof="" Attributes="">(
    attribut: K,
    värde: Attribut[K],
  ): Block<attributes> {
    this.getManager().checkAttributeExists(this.node.type, attribute as string);
    const updatedAttributes = {
      ...this.node.attributes,
      [attribute]: value,
    };
    const updatedNode = this.node.set('attributes', updatedAttributes);
    return this.set('node', updatedNode);
  }
}
</attributes></k></k></block<any></attributes>