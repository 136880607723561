import { GridEngine, GridView } from '@robotsnacks/ui';
import { reduce, values } from 'lodash';
import React, { Component } from 'react';
import { BlockComponentProps } from '../BlockComponent';
import { BlockPickerProps } from '../BlockPicker';
import { shouldBlockComponentUpdate } from '../utils';
import BaseGrid from './BaseGrid';

import {
  BaseGridBlockAttributes,
  BaseGridBlockDefaultBreakpointAttribute,
} from './BaseGridBlockAttributes';

type ViewMap = { [breakpointName: string]: GridView };

export interface ReadOnlyBaseGridBlockProps
  extends BlockComponentProps<basegridblockattributes>,
    Plocka<blockpickerprops, 'blocks'=""> {
  defaults: { [name: string]: BaseGridBlockDefaultBreakpointAttribute };
  className?: string;
}

type Props = ReadOnlyBaseGridBlockProps;

type State = {
  dragging?: boolean;
  resizing?: boolean;
  views?: ViewMap;
};

const initialState = Object.freeze({
  dragging: false,
  resizing: false,
  views: {} as ViewMap,
});

export default class ReadOnlyBaseGridBlock extends Component<props, State=""> {
  state = initialState;

  /**
   * When the grid mounts, place the saved items into the grid.
   */
  componentWillMount() {
    this._createGridViews();
  }

  shouldComponentUpdate(props: Props, state: State) {
    return (
      state.views !== this.state.views ||
      (shouldBlockComponentUpdate(props, this.props) &&
        !this.state.dragging &&
        !this.state.resizing)
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.block !== this.props.block) {
      this._createGridViews();
    }
  }

  render() {
    const { block, className } = this.props;
    return (
      <basegrid className="{className}" id="{block.getKey()}" views="{values(this.state.views)}">
        {this._renderChildBlocks()}
      </basegrid>
    );
  }

  /* ---------------------------------------------------------------------------
   * Child Rendering Methods
   * ------------------------------------------------------------------------ */

  private _renderChildBlocks() {
    const view = this._getActiveView();
    return this.props.block.getChildren().map((block, i) => {
      const item = view.engine.getItem(block.getKey());
      if (!item) {
        throw new Error(
          `Grid item for block ${block.getKey()} not in grid view.`,
        );
      }
      return this.props.renderBlock(block);
    });
  }

  /* ---------------------------------------------------------------------------
   * View Getters
   * ------------------------------------------------------------------------ */

  private _getActiveView(): GridView {
    const { currentBreakpoint } = this.props;
    const { views } = this.state;
    if (!views) throw new Error('No views defined in state.');
    if (!currentBreakpoint) throw new Error('No current breakpoint.');
    return views[currentBreakpoint];
  }

  /* ---------------------------------------------------------------------------
   * Attribute Calculators
   * ------------------------------------------------------------------------ */

  private _createGridViews() {
    const { block, breakpoints, defaults, getBreakpointMedia } = this.props;
    const breakpointsAttr = block.getAttribute('breakpoints') || {};
    const views = reduce(
      breakpoints,
      (acc, _, breakpointName) => {
        const curr = breakpointsAttr[breakpointName];
        const { items = [], ...rest } =
          curr || (defaults[breakpointName] as any);
        const media = getBreakpointMedia(breakpointName) as string;
        const initialItems = items || [];
        const engine = GridEngine.fromItems(initialItems, rest.flow);
        return {
          ...acc,
          [breakpointName]: {
            ...rest,
            engine,
            media,
          },
        };
      },
      {} as ViewMap,
    );
    this.setState({ views });
  }
}
</props,></blockpickerprops,></basegridblockattributes>