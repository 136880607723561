import { CodeBlock } from '@robotsnacks/icons';
import React from 'react';

export default {
  attributes: {
    html: { type: 'identity' },
    source: { type: 'identity' },
  },
  icon: <codeblock></codeblock>,
  namn: 'CODE',
  title: 'Kod',
  v: 0,
};
