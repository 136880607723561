import { ActionDelete, ActionEdit, ActionSave } from '@robotsnacks/icons';
import {
  Toolbar,
  ToolbarButton,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';
import React, { Component, Fragment } from 'react';
import { MenuItem } from './Menu';
import PageListFlyout from './PageListFlyout';

const styles = createStyles<'root' | 'input'>(theme => ({
  root: {},
  input: {
    '-webkit-appearance': 'none',
    background: 'transparent',
    border: 0,
    display: 'inline',
    font: 'inherit',
    height: '1em',
    margin: 0,
    outline: 0,
    padding: 0,
  },
}));

export interface WritableNavItemProps {
  menuItem: MenuItem;
  onChange?: (item: MenuItem) => void;
  onDeleteClick?: () => void;
  onEditDone?: any;
  onEditStart?: any;
  onFlyoutClose?: any;
  onFlyoutOpen?: any;
  pageList: any;
  preventFlyout?: boolean;
}

type Props = WithStyles<writablenavitemprops, typeof="" styles="">;

type State = {
  flyoutOpen?: boolean;
  mouseOver?: boolean;
  editing?: boolean;
};

class WritableNavItem extends Component<props, State=""> {
  state: State = {};

  public render() {
    const {
      classes,
      menuItem: { title },
    } = this.props;

    return (
      <div style="{{" cursor:="" 'move',="" position:="" 'relative'="" }}="" onMouseEnter="{this._handleMouseEnter}" onMouseLeave="{this._handleMouseLeave}">
        {this.state.editing && (
          <input autoFocus="" className="{classes.input}" onChange="{this._handleTitleChange}" value="{title}">
        )}
        {!this.state.editing && titel}
        <toolbar onMouseDown="{e" ==""> e.stopPropagation()}
          style={{
            cursor: 'auto',
            position: 'absolute',
            right: '-4px',
            top: '-5px',
            height: '24px',
            display:
              this.state.mouseOver ||
              this.state.flyoutOpen ||
              this.state.editing
                ? 'inline-flex'
                : 'none',
            transform: 'translateX(calc(100% - 10px))',
          }}
        >
          {!this.state.editing && (
            <fragment>
              <toolbarbutton onClick="{this.props.onDeleteClick}">
                <actiondelete></actiondelete>
              </toolbarbutton>
              <toolbarbutton onClick="{this._handleEditClick}">
                <actionedit></actionedit>
              </toolbarbutton>
              <pagelistflyout pageList="{this.props.pageList}" onFocus="{this._handleFocus}" onBlur="{this._handleBlur}" onSelect="{this._handleSelect}"></pagelistflyout>
            </fragment>
          )}
          {this.state.editing && (
            <fragment>
              <toolbarbutton onClick="{this._handleSaveClick}">
                <actionsave></actionsave>
              </toolbarbutton>
            </fragment>
          )}
        </toolbar>
      </div>
    );
  }

  private _handleEditClick = () => {
    this.setState({ editing: true });
    this.props.onEditStart();
  };

  private _handleSaveClick = () => {
    this.setState({ editing: false });
    this.props.onEditDone();
  };

  private _handleMouseEnter = () => {
    if (this.props.preventFlyout) return;
    this.setState({ mouseOver: true });
  };

  private _handleMouseLeave = () => {
    this.setState({ mouseOver: false });
  };

  private _handleFocus = () => {
    this.setState({ flyoutOpen: true });
    this.props.onFlyoutOpen();
  };

  private _handleBlur = () => {
    this.setState({ flyoutOpen: false });
    this.props.onFlyoutClose();
  };

  private _handleSelect = (page: any) => {
    const { menuItem, onChange } = this.props;
    onChange!({ ...menuItem, to: page.path });
    this.setState({ flyoutOpen: false });
    this.props.onFlyoutClose();
  };

  private _handleTitleChange = (e: any) => {
    const { menuItem, onChange } = this.props;
    onChange!({ ...menuItem, title: e.target.value });
  };
}

export default withStyles(styles)(WritableNavItem);
</props,></writablenavitemprops,>