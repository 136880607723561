/* tslint:disable variable-name */

import gql from 'graphql-tag';
import { get, identity } from 'lodash';
import React, { Component, ReactNode } from 'react';
import { Query } from 'react-apollo';
import Block from '../Block';
import { BlockComponentProps } from '../BlockComponent';
import ToolbarHover from '../ToolbarHover';
import ToolbarWrapper from '../ToolbarWrapper';
import { FeedBlockAttributes } from './FeedBlockAttributes';
import FeedBlockPlaceholder from './FeedBlockPlaceholder';
import FeedBlockToolbar from './FeedBlockToolbar';
import FeedGrid from './FeedGrid';
import FeedList from './FeedList';
import resolveFeed from './query';
import resolveCql from './resolve-cql';

export interface FeedBlockProps
  extends BlockComponentProps<feedblockattributes> {
  onDelete?: (block: Block<feedblockattributes>) => void;
  parentToolbar?: ReactNode;
  parentToolbarItems?: ReactNode;
  standardinställningar?: alla;
  imageClient?: vilken som helst;
}

typ Props = FeedBlockProps;

export default class FeedBlock extends Komponent<props> {
  public shouldComponentUpdate(prevProps: Props) {
    return this.props.block !== prevProps.block;
  }

  public render() {
    const { block, parentToolbar, parentToolbarItems } = this.props;
    const flow = block.getAttribute('flow');

    return (
      <toolbarhover block="{block}">
        <div id="{block.getKey()}" style="{{" display:="" 'flex',="" flex:="" 1,="" position:="" 'relative',="" width:="" '100%',="" }}="">
          <toolbarwrapper>
            {förälderverktygsfält}
            <feedblocktoolbar block="{block}" initiated="{!!flow}" cql="{block.getAttribute('cql')" ||="" ''}="" onDeleteClick="{this._handleDeleteClick}" onCqlChange="{this._handleCqlChange}" parentItems="{parentToolbarItems}"></feedblocktoolbar>
          </toolbarwrapper>
          {flöde && this._renderFeed()}
          {!flöde && this._renderPlaceholder()}
        </div>
      </toolbarhover>
    );
  }

  private _renderPlaceholder() {
    return <feedblockplaceholder onSaveClick="{this._handleChange}"></feedblockplaceholder>;
  }

  private _renderFeed() {
    const { block } = this.props;
    const cql = (block.getAttribute('cql') || resolveCql(block)).trim();
    return (
      <query query="{resolveFeed}" skip="{!cql}" ssr="{false}" variables="{{" cql="" }}="">
        {({ data, error, loading, ...rest }) => {
          if (loading) {
            return null;
          }
          if (error) {
            return (
              <div>
                <p>
                  Det måste ha varit något fel på din fråga.
                </p>
                <p>
                  Försök att ändra foderfrågan genom att trycka på inställningsikonen
                  ovan.
                </p>
              </div>
            );
          }
          const flow = this.props.block.getAttribute('flow');
          const feed = get(data, 'feed.edges', []).map(
            (edge: any) => edge.node,
          );

          if (flow === 'list') {
            return <feedlist feed="{feed}" block="{block}"></feedlist>;
          } else {
            return (
              <feedgrid flow="{flow}" feed="{feed}" block="{this.props.block}" breakpointNames="{this.props.breakpointNames}" breakpoints="{this.props.breakpoints}" currentBreakpoint="{this.props.currentBreakpoint}" defaults="{this.props.defaults}" getBreakpointMedia="{this.props.getBreakpointMedia}" imageClient="{this.props.imageClient}"></feedgrid>
            ) som alla andra;
          }
        }}
      </query>
    );
  }

  private _handleCqlChange = (cql: string) => {
    const { block, getValue, onChange } = this.props;
    onChange(getValue().replace(block.setAttribute('cql', cql)));
  };

  private _handleChange = (e: any) => {
    const { block, getValue, onChange } = this.props;
    const updatedBlock = block
      .setAttribute('cql', e.cql)
      .setAttribute('type', 'event')
      .setAttribute('flow', e.flow)
      .setAttribute('after', e.after)
      .setAttribute('before', e.before)
      .setAttribute(
        'withTags',
        e.withTags
          .toLowerCase()
          .replace(/,/, '')
          .split(/\s+/g)
          .filter(identity),
      )
      .setAttribute(
        'excludeTags',
        e.excludeTags
          .toLowerCase()
          .replace(/,/, '')
          .split(/\s+/g)
          .filter(identity),
      );
    onChange(getValue().replace(updatedBlock));
  };

  private _handleDeleteClick = () => {
    const { block, getValue, onChange, onDelete } = this.props;
    onChange(getValue().del(block));
    if (onDelete) {
      onDelete(block);
    }
  };
}
</props></feedblockattributes></feedblockattributes>