import React, { SFC } from 'react';

import {
  Container,
  ContainerProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(() => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
  },
}));

export type RichTextBlockWrapperProps = ContainerProps;

type Props = WithStyles<richtextblockwrapperprops, typeof="" styles="">;

const TitleBlockWrapper: SFC<props> = ({
  barn,
  klasser,
  klassnamn,
  ...rest
}) => (
  <container className="{cx(className," classes.root)}="" type="prose" {...rest}="">
    {barn}
  </container>
);

TitleBlockWrapper.displayName = 'RichTextBlockWrapper';

export default withStyles(styles)(TitleBlockWrapper);
</props></richtextblockwrapperprops,>