/* tslint:disable */

import { Link } from '@robotsnacks/icons';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { debounce, get, noop } from 'lodash';
import React, { Component, Fragment } from 'react';

import {
  Button,
  Input,
  ListPicker,
  ToolbarFlyout,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const query = gql`
  query pages($first: Int, $after: Cursor, $query: String) {
    pages(first: $first, after: $after, query: $query) {
      edges {
        node {
          id
          title
          description
          path
          images {
            type
            image {
              id
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const styles = createStyles<'root'>(theme => ({
  root: {
    fontSize: 13,
    width: 280,
  },
}));

export interface PageListFlyoutProps {
  onSelect?: (page: any) => void;
  onSaveClick?: (href: string) => void;
  href?: string;
}

type Props = WithStyles<pagelistflyoutprops, typeof="" styles=""> &
  typeof defaultProps;

type State = {
  href?: string;
  in?: boolean;
  view?: 'internal' | 'external';
  query?: string;
};

const defaultProps = Object.freeze({
  onDeleteClick: noop,
  onSaveClick: noop,
});

const getInitialState = (props: Props): State => ({
  href: props.href || '',
  in: false,
  view: props.href ? 'external' : 'internal',
});

class PageListFlyout extends Component<props, State=""> {
  static defaultProps = defaultProps;
  state = getInitialState(this.props);

  private focused: boolean;
  private focusTimer: any;

  public componentWillUnmount() {
    if (this.focusTimer) {
      clearTimeout(this.focusTimer);
    }
  }

  render() {
    const { classes } = this.props;
    let fetchingMore = false;
    const flyout = (
      <div className="{classes.root}">
        <div style="{{" display:="" 'flex'="" }}="">
          <div style="{{" padding:="" '0.5rem',="" textAlign:="" 'center',="" flex:="" 1,="" cursor:="" 'pointer',="" fontWeight:="" this.state.view="==" 'internal'="" ?="" 'bold'="" :="" 'normal',="" }}="" onClick="{()" ==""> this.setState({ view: 'internal' })}
          >
            Internal Link
          </div>
          <div style="{{" padding:="" '0.5rem',="" textAlign:="" 'center',="" flex:="" 1,="" cursor:="" 'pointer',="" fontWeight:="" this.state.view="==" 'external'="" ?="" 'bold'="" :="" 'normal',="" }}="" onClick="{()" ==""> this.setState({ view: 'external' })}
          >
            External Link
          </div>
        </div>
        {this.state.view === 'internal' && (
          <div>
            <div style="{{" padding:="" '0.5rem'="" }}="">
              <input onChange="{e" ==""> this._handleSearchChange(e.currentTarget.value)}
                placeholder="Search"
                style={{
                  border: 'none',
                  borderBottom: '1px solid #eee',
                  outline: 'none',
                  width: '100%',
                }}
              />
            </div>
            <query fetchPolicy="network-only" query="{query}" variables="{{" first:="" 15,="" query:="" this.state.query="" }}="">
              {({ data, loading, fetchMore, ...rest }) => {
                const pages = get(data, 'pages.edges', [])
                  .map((edge: any) => edge.node)
                  .map((node: any) => ({
                    key: node.id,
                    label: node.title || 'Untitled',
                    value: node,
                  }));
                return (
                  <listpicker onScroll="{e" ==""> {
                      const {
                        offsetHeight,
                        scrollHeight,
                        scrollTop,
                      } = e.currentTarget;
                      if (
                        !loading &&
                        !fetchingMore &&
                        data &&
                        data.pages &&
                        data.pages.pageInfo.hasNextPage &&
                        scrollHeight - scrollTop - offsetHeight < 200
                      ) {
                        fetchingMore = true;
                        fetchMore({
                          updateQuery: (prev, { fetchMoreResult }) => {
                            if (!fetchMoreResult) {
                              return prev;
                            }
                            return {
                              ...prev,
                              pages: {
                                ...prev.pages,
                                edges: [
                                  ...prev.pages.edges,
                                  ...fetchMoreResult.pages.edges,
                                ],
                                pageInfo: fetchMoreResult.pages.pageInfo,
                              },
                            };
                          },
                          variables: {
                            first: 15,
                            after: data.pages.pageInfo.endCursor,
                            query: this.state.query,
                          },
                        });
                      }
                    }}
                    onSelect={this._handleSelect}
                    options={pages}
                  />
                );
              }}
            </listpicker></query>
          </div>
        )}
        {this.state.view === 'external' && (
          <div style="{{" padding:="" '0.5rem'="" }}="">
            <input label="URL" value="{this.state.href}" onChange="{href" ==""> this.setState({ href })}
              style={{ margin: '0.5rem 0' }}
            />
            <button onClick="{()" ==""> {
                this.props.onSaveClick(this.state.href);
                this.setState({ in: false });
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
    );

    returnera (
      <toolbarflyout flyout="{flyout}" in="{this.state.in}" onFocus="{this._handleFocus}" onBlur="{this._handleBlur}">
        <link>
      </toolbarflyout>
    );
  }

  private _handleSearchChange = debounce((query: string) => {
    this.setState({ query });
  });

  private _handleSelect = (page: any) => {
    if (this.props.onSelect) {
      this.props.onSelect(page);
    }
    this.setState({ in: false });
  };

  private _handleCancelClick = () => {
    this.setState({ in: false });
  };

  private _handleDeleteClick = () => {
    this.setState({ in: false });
    this.props.onDeleteClick();
  };

  private _handleFocus = () => {
    this.focused = true;
    this.setState({ in: true });
  };

  private _handleBlur = () => {
    this.focused = false;
    this.focusTimer = setTimeout(() => {
      if (this.focused === false) {
        this.setState({ in: false });
      }
    }, 20);
  };
}

export default withStyles(styles)(PageListFlyout);
</props,></pagelistflyoutprops,>