import React, { Component } from 'react';

import {
  Grid,
  GridProps,
  WithStyles,
  createStyles,
  cx,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root'>(theme => ({
  root: {
    background: theme.color('background'),
    padding: 6,
    position: 'relative',
  },
}));

export interface BaseGridProps extends GridProps {
  gridRef?: (ref: any | null) => void;
}

type Props = WithStyles<basegridprops, typeof="" styles="">;

klass BaseGrid utökar komponent<props> {
  render() {
    const { children, classes, className, gridRef, ...rest } = this.props;
    return (
      <grid className="{cx(classes.root," className,="" 'cs-base-grid')}="" innerRef="{gridRef}" {...rest}="">
        {barn}
      </grid>
    );
  }
}

export default withStyles(styles)(BaseGrid);
</props></basegridprops,>