import { WithStyles, createStyles, cx, withStyles } from '@robotsnacks/ui';
import React, { HTMLAttributes, SFC } from 'react';

const styles = createStyles<'root'>(theme => ({
  root: {
    left: 0,
    position: 'absolute',
    top: -12,
    zIndex: 50,
  },
}));

export interface ToolbarWrapperProps extends HTMLAttributes<htmldivelement> {}

typ Rekvisita = WithStyles<toolbarwrapperprops, typeof="" styles="">;

const ToolbarWrapper: SFC<props> = ({klasser, klassnamn, tema, ...rest }) => (
  <div className="{cx(classes.root," className)}="" {...rest}=""></div>
);

export default withStyles(styles)(ToolbarWrapper);
</props></toolbarwrapperprops,></htmldivelement>