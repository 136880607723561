import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

export interface BaseGridBlockToolbarProps
  extends Pick<blocktoolbarprops, 'block'=""> {
  onDeleteClick?: () => void;
  title?: string;
}

const BaseGridBlockToolbar: SFC<basegridblocktoolbarprops> = ({
  onDeleteClick,
  titel,
  ...rest
}) => (
  <blocktoolbar title="{title}" {...rest}="">
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{title}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator>
    <toolbarbutton>
      <icon>åtgärd:inställningar</icon>
    </toolbarbutton> */}
  </blocktoolbar>
);

export default BaseGridBlockToolbar;
</basegridblocktoolbarprops></blocktoolbarprops,>