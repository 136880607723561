import React from 'react';
import { SvgAssetProps } from './svg-asset-props';
import { cx } from '@mint/ui';
import { kebabCase } from 'lodash';

export const makeSvgAsset = (
  Component: React.FunctionComponent<react.svgprops<svgsvgelement>>,
  displayName: sträng,
): React.funktionskomponent<svgassetprops> => {
  const Icon: React.FunctionComponent<svgassetprops> = (
    props,
  ): React.ReactElement => {
    return (
      <component {...props}="" className="{cx(kebabCase(displayName)," props.className)}=""></component>
    );
  };

  Icon.displayName = visningsnamn;

  returnera Ikon;
};
</svgassetprops></svgassetprops></react.svgprops<svgsvgelement>