import React, { Fragment, useState } from 'react';
import Backdrop from '../backdrop';
import Header from '../header';
import Main from '../main';
import { ThemeProvider as MintThemeProvider } from '@mint/ui';
import { NavContainer } from '../nav';
import { PublicAppProps } from './public-app-props';
import { PublicAppRoutes } from '../public-app-routes';
import { Query } from 'react-apollo';
import { admin } from '../../../themes/admin';
import { get } from 'lodash';
import gql from 'graphql-tag';

const getThemeQuery = gql`
  query getTheme {
    site {
      id
      legacyTheme
      legacyLogoImage {
        id
      }
      headerOverrideHtml
      footerOverrideHtml
    }
  }
`;

export const PublicApp: React.FunctionComponent<
  PublicAppProps
> = (): React.ReactElement => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [editingMenu, setEditingMenu] = useState(false);

  return (
    <query query="{getThemeQuery}">
      {({ data, loading }) => {
        if (loading && !get(data, 'site.legacyTheme')) return null;
        return (
          <mintthemeprovider theme="{admin}">
            <fragment>
              {!data.site.headerOverrideHtml && (
                <header siteId="{data.site.id}" logoId="{" data.site.legacyLogoImage="" &&="" data.site.legacyLogoImage.id="" }="" onMenuClick="{()" ==""> setMenuOpen(true)}
                />
              )}
              {data.site.headerOverrideHtml && (
                <div dangerouslySetInnerHTML="{{" __html:="" data.site.headerOverrideHtml,="" }}=""></div>
              )}
              <main customHeader="{!!data.site.headerOverrideHtml}">
                <publicapproutes></publicapproutes>
              </main>
              {data.site.footerOverrideHtml && (
                <div dangerouslySetInnerHTML="{{" __html:="" data.site.footerOverrideHtml,="" }}=""></div>
              )}
              {!data.site.headerOverrideHtml && (
                <navcontainer visible="{menuOpen" ||="" editingMenu}="" onEditStart="{()" ==""> setEditingMenu(true)}
                  onEditDone={() => setEditingMenu(false)}
                  onItemClick={() => setMenuOpen(false)}
                />
              )}
              <backdrop visible="{menuOpen" ||="" editingMenu}="" onClick="{()" ==""> setMenuOpen(false)}
              />
            </backdrop></navcontainer></header></fragment>
          </mintthemeprovider>
        );
      }}
    </query>
  );
};

PublicApp.displayName = "PublicApp";
