import { ActionEdit } from '@robotsnacks/icons';
import { ToolbarButton } from '@robotsnacks/ui';
import React, { SFC } from 'react';
import BlockToolbar, { BlockToolbarProps } from '../BlockToolbar';
import DeleteBlockFlyout from '../DeleteBlockFlyout';

type Props = { onDeleteClick?: () => void; onEditClick?: () => void } & Pick<
  BlockToolbarProps,
  'block' | 'parentItems'
>;

const TITLE = 'Code';

const CodeToolbar: SFC<props> = ({ onDeleteClick, onEditClick, ...rest }) => (
  <blocktoolbar title="{TITEL}" {...rest}="">
    <toolbarbutton onClick="{onEditClick}">
      <actionedit></actionedit>
    </toolbarbutton>
    <deleteblockflyout onDeleteClick="{onDeleteClick}" title="{TITEL}"></deleteblockflyout>
    {/* <toolbarseparator></toolbarseparator>
    <toolbarbutton>
      <icon>åtgärd:inställningar</icon>
    </toolbarbutton> */}
  </blocktoolbar>
);

export default CodeToolbar;
</props>