import React, { CSSProperties, SFC } from 'react';
import { compose, cx } from '../utils';

import { WithStyles, createStyles, withStyles } from '../styles';

export type ArrowDirection = 'up' | 'down' | 'right' | 'left';

const DEFAULT_ARROW_SIZE = 6;

const negative = (n: number) => -n;
const minusOne = (n: number) => n - 1;

const styles = createStyles<
  'root' | 'up' | 'down' | 'left' | 'right',
  ArrowProps
>(theme => {
  const defaultColor = theme.color('background');
  const defaultBorderColor = theme.color('grey', '200');

  const arrowColor: any = (props: ArrowProps) => props.color || defaultColor;
  const borderColor: any = (props: ArrowProps): string =>
    props.borderColor || defaultBorderColor;

  const arrowSize: any = ({ size = DEFAULT_ARROW_SIZE }: ArrowProps) => size;

  const innerSize: any = compose(
    minusOne,
    arrowSize,
  );

  const arrowSizeNegative: any = compose(
    negative,
    arrowSize,
  );

  const innerSizeNegative: any = compose(
    negative,
    innerSize,
  );

  return {
    root: {
      position: 'absolute',
      pointerEvents: 'none',
      '&::before, &::after': {
        borderColor: 'rgba(0, 0, 0, 0)',
        border: 'solid transparent',
        content: '""',
        height: 0,
        position: 'absolute',
        pointerEvents: 'none',
        width: 0,
      },
      '&::before': {
        borderWidth: arrowSize,
      },
      '&::after': {
        borderWidth: innerSize,
      },
    },
    up: {
      bottom: '100%',
      left: '50%',
      '&::before, &::after': {
        bottom: '100%',
      },
      '&::before': {
        borderBottomColor: borderColor,
        marginLeft: arrowSizeNegative,
      },
      '&::after': {
        borderBottomColor: arrowColor,
        marginLeft: innerSizeNegative,
      },
    },
    down: {
      top: '100%',
      left: '50%',
      '&::before, &::after': {
        top: '100%',
      },
      '&::before': {
        borderTopColor: borderColor,
        marginLeft: arrowSizeNegative,
      },
      '&::after': {
        borderTopColor: arrowColor,
        marginLeft: innerSizeNegative,
      },
    },
    left: {
      right: '100%',
      top: '50%',
      '&::before, &::after': {
        right: '100%',
      },
      '&::before': {
        borderRightColor: borderColor,
        marginTop: arrowSizeNegative,
      },
      '&::after': {
        borderRightColor: arrowColor,
        marginTop: innerSizeNegative,
      },
    },
    right: {
      left: '100%',
      top: '50%',
      '&::before, &::after': {
        left: '100%',
      },
      '&::before': {
        borderLeftColor: borderColor,
        marginTop: arrowSizeNegative,
      },
      '&::after': {
        borderLeftColor: arrowColor,
        marginTop: innerSizeNegative,
      },
    },
  };
});

export type ArrowProps = {
  borderColor?: string;
  children?: never;
  className?: string;
  color?: string;
  direction?: ArrowDirection;
  size?: number;
  style?: CSSProperties;
};

type Props = WithStyles<arrowprops, typeof="" styles="">;

konst pil: SFC<props> = ({
  barn,
  klassnamn,
  klasser,
  färg,
  riktning = 'ner',
  storlek,
  tema,
  ...rest
}) => (
  <div className="{cx(className," classes.root,="" (classes="" as="" any)[direction])}="" {...rest}=""></div>
);

export default withStyles(styles)(Arrow);
</props></arrowprops,>