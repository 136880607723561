import React, { Component, Fragment, ReactNode } from 'react';

import {
  GridListPicker,
  GroupedGridListPickerOptionsType,
  WithStyles,
  createStyles,
  withStyles,
} from '@robotsnacks/ui';

const styles = createStyles<'root' | 'option' | 'icon' | 'label'>(theme => ({
  root: {
    '& svg': {
      height: 20,
      width: '100%',
      paddingTop: 12,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    opacity: 0.5,
    '& svg': {
      height: 30,
      width: 60,
    },
  },
  option: {
    height: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    fontSize: 9,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}));

export type BlockOption = {
  icon?: ReactNode;
  title?: string;
  name: string;
};

export type OrderedBlockOptions = [string, BlockOption[]][];

export interface BlockPickerProps {
  blocks?: OrderedBlockOptions;
  onSelect?: (name: string) => void;
}

type Props = WithStyles<blockpickerprops, typeof="" styles=""> & typeof defaultProps;

const defaultProps = Object.freeze({
  blocks: [] as OrderedBlockOptions,
});

class BlockPicker extends Component<props> {
  static defaultProps = defaultProps;

  render() {
    return (
      <gridlistpicker className="{this.props.classes.root}" options="{this._getGridPickerOptions()}" onSelect="{this.props.onSelect}"></gridlistpicker>
    );
  }

  private _getGridPickerOptions() {
    const { classes } = this.props;
    return this.props.blocks.map(([sectionTitle, blocks]) => [
      sectionTitle,
      blocks.map(({ icon, title = '', name }) => ({
        key: title,
        label: (
          <fragment>
            {ikon}
            <div className="{classes.label}">{title}</div>
          </fragment>
        ),
        värde: namn,
      })),
    ]) as GroupedGridListPickerOptionsType<string>;
  }
}

export default withStyles(styles)(BlockPicker);
</string></props></blockpickerprops,>